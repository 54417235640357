import '../App.css';
import { useParams } from 'react-router-dom';
import { getOriginalUrl } from '../api/ApiCalls';
import React, { useState, useEffect, useMemo } from 'react';

const Redirect = () => {
  const [originalUrl, setOriginalUrl] = useState("google.com");
  const [redirectCancelled, setRedirectCancelled] = useState(false);
  const [remainingTime, setRemainingTime] = useState(5);
  const { shortValue } = useParams();
  const validShortValueRegex = useMemo(() => /^[A-Za-z0-9_-]{1,64}$/, []);

  useEffect(() => {

    if (!validShortValueRegex.test(shortValue)) {
      return;
    }

    getOriginalUrl(shortValue)
      .then(result => {
        if (result !== null) {
          if (!result.startsWith("http")) {
            result = `https://${result}`;
          }
          setOriginalUrl(result);
          const timer = setTimeout(() => {
            if (!redirectCancelled) {
              window.location.replace(result);
            }
          }, 5000);

          const interval = setInterval(() => {
            setRemainingTime(prevTime => {
              if (prevTime <= 1) {
                clearInterval(interval);
                return 0;
              }
              return prevTime - 1;
            });
          }, 1000);

          return () => {
            clearTimeout(timer);
            clearInterval(interval);
          };
        }
      })
      .catch(error => {
        console.log("ERROR", error);
      });

  }, [redirectCancelled, shortValue, validShortValueRegex]);

  if (!validShortValueRegex.test(shortValue)) {
    return (
      <div className='center-screen'>
        <h1 className="mt-4 text-2xl font-bold tracking-tight text-black sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
          <span className="block text-orange-500">Oops! URL Not Found.</span>
        </h1>
      </div>
    );
  }

  if (originalUrl === null) {
    return (
      <div className='center-screen'>
        <h1 className="mt-4 text-2xl font-bold tracking-tight text-black sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
          <span className="block text-orange-500">...</span>
        </h1>
      </div>
    );
  }

  return (
    <div className='center-screen'>
      <h1 class="mt-4 text-4xl font-bold tracking-tight text-black sm:mt-5 sm:text-5xl lg:mt-6 xl:text-5xl text-orange-500">CUTMYURL</h1>
      <h1 className="m-10 text-2xl font-bold tracking-tight text-black">
        <span className="block text-500">
          {"Redirecting in " + remainingTime + " to:"}
        </span>
        <br />
        <a href={originalUrl} className="block text-blue-500 break-all">{originalUrl}</a>
        <br />
      </h1>
      <button
        onClick={() => {
          setRedirectCancelled(true);
          window.location.replace('/');
        }}
        className="block w-1/2 rounded-md bg-orange-500 py-3 px-4 font-medium text-white shadow hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-orange-300 focus:ring-offset-2 focus:ring-offset-gray-900"
      >
        Cancel Redirect
      </button>
    </div>
  );
};

export default Redirect;